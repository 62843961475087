import Head from "next/head";

import { SolsteadPageComponent } from "../../pages-components/solstead";

export async function getServerSideProps({ params }) {
  const { solsteadsId } = params;
  return { props: { solsteadsId } };
}

type SolsteadPageProps = {
  solsteadsId: string;
};

export default function SolsteadPage({ solsteadsId }: SolsteadPageProps) {
  return (
    <>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <title>Solsteads - Home For Your NFTs</title>
        <meta
          name="description"
          content="You’ve treated yourself to some NFTs. Now it’s time to treat them
          to a beautiful home. Each Solstead is one of 2,472 unique
          homes in the first ever digital real estate on the Solana
          blockchain."
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <SolsteadPageComponent solsteadsId={solsteadsId} guestId={null} />
    </>
  );
}
